<template>
  <div class="c-text">
    <div
      v-if="data && data.content"
      class="c-text__content"
      v-md-html="data.content.content"
    />
  </div>
</template>

<script>
export default {
  name: 'Text',

  props: {
    data: Object,
  },
}
</script>

<style lang="scss">
.c-text {
  display: block;
  padding: 1rem;
  margin: 1.375rem auto;

  border: 2px solid $oppstart-primary-dark;
  border-radius: 10px;

  @include bp(medium) {
    padding: 0 3.125rem 2rem 3.125rem;
  }

  .v-content-renderer--borderless & {
    border: none;
    border-radius: 0;
    padding: 0;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 2.375rem;
    margin-top: 0.6rem;

    @include bp(medium) {
      margin-top: 3rem;
    }
  }

  h3,
  h4,
  h5 {
    margin-bottom: 1.8rem;
    margin-top: 2rem;
  }

  ul,
  ol {
    padding-left: 1rem;
  }

  a {
    font-weight: 700;
  }

  img {
    display: inline-block;
    width: auto;
    max-width: 100%;
  }
}
</style>
